@font-face {
  font-family: 'NunitoSans';
  // src: url('../../../fonts/NunitoSans/');
  src: url('../../fonts/NunitoSans/NunitoSans-Regular.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-Regular.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-Regular.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-Bold.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-Bold.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-Bold.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraBold.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraBold.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraBold.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-SemiBold.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-SemiBold.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-SemiBold.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraLight.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraLight.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraLight.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-BlackItalic.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-BlackItalic.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-BlackItalic.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-Light.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-Light.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-Light.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-SemiBoldItalic.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-SemiBoldItalic.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-SemiBoldItalic.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-BoldItalic.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-BoldItalic.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-BoldItalic.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-LightItalic.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-LightItalic.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-LightItalic.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-Black.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-Black.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-Black.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-Black.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraLightItalic.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraLightItalic.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraLightItalic.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/NunitoSans/NunitoSans-Italic.eot');
  src: url('../../fonts/NunitoSans/NunitoSans-Italic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/NunitoSans/NunitoSans-Italic.woff2') format('woff2'),
      url('../../fonts/NunitoSans/NunitoSans-Italic.woff') format('woff'),
      url('../../fonts/NunitoSans/NunitoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

