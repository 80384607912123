////////// SIDEBAR ////////
$sidebar-width-lg: 220px;
$sidebar-width-mini: 185px; 
$sidebar-width-icon: 70px;

$sidebar-light-bg: #ffffff;
$sidebar-light-menu-color: #686868;
$sidebar-light-submenu-color: #656565;
$sidebar-light-menu-active-bg: rgba(77, 131, 255, 0.07);
$sidebar-light-menu-active-color: #08279c;
$sidebar-light-menu-hover-bg: $content-bg; 
$sidebar-light-menu-hover-color: #56595a;
$sidebar-light-submenu-hover-color: $black;
$sidebar-light-submenu-active-color: $black;
$sidebar-light-menu-icon-color: $black;
$sidebar-light-menu-arrow-color: $black;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;
$sidebar-light-icon-only-menu-box-shadow: 4px 4px 7px 0px rgba(182, 185, 189, 0.25);
$sidebar-light-icon-only-menu-box-shadow-rtl: -4px 4px 7px 0px rgba(182, 185, 189, 0.25);

$sidebar-dark-bg: #282f3a;
$sidebar-dark-menu-color: #d0cfcf;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color: #c0bbbb;
$sidebar-dark-menu-hover-bg: #59606b;
$sidebar-dark-menu-hover-color: #d0cfcf;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: #9a94a7;
$sidebar-dark-menu-icon-color: $sidebar-dark-menu-color;
$sidebar-dark-profile-name-color: #ffffff;
$sidebar-dark-profile-title-color: #8d9498;

$sidebar-menu-font-size: .875rem;
$sidebar-menu-font-weight: 400;
$sidebar-menu-padding-left: 1.25rem;
$sidebar-menu-padding-right: 1.25rem;
$sidebar-menu-padding-top: .30rem;
$sidebar-menu-padding-bottom: .40rem;

$sidebar-submenu-padding: .25rem 0 0 0.80rem;
$sidebar-submenu-margin: 0;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding: 1rem 2.5rem;

$sidebar-icon-font-size: 0.9rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$sidebar-box-shadow: 6px 16px 31px -18px #b7bcd1;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: color(white);
$navbar-height: 69px;
$navbar-menu-color: #9b9b9b;
$navbar-font-size: 1rem;
$navbar-icon-font-size: .9375rem;
$navbar-border-color: $border-color;
$navbar-light-menu-hover-bg: rgba(77, 131, 255, 0.07);

///////// NAVBAR ////////


