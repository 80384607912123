/* Lists */

ul,
ol,
dl {
  padding-left: 1rem;
  font-size: $default-font-size;
  li {
    line-height: 1.8;
  }
}

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0;
  li {
    padding-left: 1.5rem;
    &:before {
      font-family: "Material Design Icons";
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: .5rem;
    }
  }
}

.list-ticked {
  li {
    &:before {
      content: '\F12D';
      color: theme-color(danger);
    }
  }
}

.list-arrow {
  li {
    &:before {
      content: '\F142';
      color: theme-color(success);
    }
  }
}

.list-star {
  li {
    &:before {
      content: '\F4CE';
      color: theme-color(warning);
    }
  }
}

.solid-bullet-list {
  position: relative;
  padding-left: 0;
  .rtl & {
    padding-right: 0;
  }
  li {
    position: relative;
    list-style-type: none;
    padding-left: 25px;
    line-height: 1;
    padding-bottom: 2.125rem;
    * {
      line-height: .8;
    }
    &:before,
    &:after {
      content:"";
      position: absolute;
    }
    &:before {
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: theme-color(primary);
      z-index: 1;
    }
  }
  &:after {
    content: "";
    border: 1px solid $border-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4px;
    z-index: 0;
  }
}

.bullet-line-list {
  padding-left: 30px;
  position: relative;
  list-style-type: none;
  margin-bottom: 0;
  .rtl & {
    padding-left: unset;
    padding-right: 30px;
  }

  li {
    position: relative;
    margin-bottom: 1.3rem;

    &:before {
      width: 15px;
      height: 15px;
      left: -30px;
      top: 0;
      border: 4px solid theme-color(primary);
      margin-right: 15px;
      z-index: 2;
      background: color(white);

      .rtl & {
        left: unset;
        right: -45px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      border-radius: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:after {
    content: "";
    border: 1px solid $border-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7px;
    .rtl & {
      left: unset;
      right: 7px;
    }
  }
}

.icon-line-list {
  padding-left: 30px;
  position: relative;
  list-style-type: none;
  margin-bottom: 0;
  .rtl & {
    padding-left: unset;
    padding-right: 30px;
  }

  li {
    position: relative;
    margin-left: 2.062rem;

    h6 {
      line-height: normal;
    }

    &:before {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.375rem;
      left: -3.875rem;
      top: 0;
      margin-right: 0.937rem;
      z-index: 2;
      outline: 0.5rem solid white;

      .rtl & {
        left: unset;
        right: -45px;
      }
    }

    &:nth-child(odd) {
      &::before {
      background: theme-color(info);
      }
      &::after{
        content: "\F150";
      }
    }

    &:nth-child(even) {
      &::before {
      background: theme-color(primary);
      }
      &::after{
        content: "\F514";
      }
    }

    &::after{
      font-family: "Material Design Icons";
        font-style: normal;
        display: block;
        font-size: 1.125rem;
        line-height: 10px;
        position: absolute;
        left: -3.188rem;
        top: 0.937rem;
        color: white;
        z-index: 3;
    }

    &:before {
      content: "";
      position: absolute;
    }
  }

  &:after {
    content: "";
    width: 1px;
    background: $border-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1.187rem;
    height: calc(100% - 100px);
    .rtl & {
      left: unset;
      right: 7px;
    }
  }
}