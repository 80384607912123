/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  font-family: $type1;
  font-weight: 300;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  box-shadow: $sidebar-box-shadow;
  -webkit-box-shadow: $sidebar-box-shadow;
  -moz-box-shadow: $sidebar-box-shadow;
  -ms-box-shadow: $sidebar-box-shadow;
  border-right: 1px solid $navbar-border-color;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0 1rem 3.75rem 1rem;

    .sidebar-category {
      font-weight: 400;
      color: $navbar-menu-color;
      margin: 2.2rem 1.25rem 0 1.25rem;
      &:hover {
        > .nav-link {
          background: color(white);
        }
      }
    }
    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .collapse {
        z-index: 999;
      }
      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
        color: $sidebar-light-menu-color;
        border-radius: 6px;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;

        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-right: 1.125rem;
            .rtl & {
              margin-left: 2rem;
              margin-right: 0;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }
          &.menu-arrow {
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-left: auto;
            margin-right: 0;
            color: $sidebar-light-menu-arrow-color;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }

            &:before {
              content: "\F140";
              font-family: "Material Design Icons";
              font-style: normal;
              display: block;
              font-size: 1rem;
              line-height: 10px;
              @include transition(all .2s ease-in);
            }
          }
        }

        .menu-title {
          color: $black;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          font-weight: $sidebar-menu-font-weight;
          line-height: 1;
          vertical-align: middle;
          margin-top: 0.3125rem;
        }

        .badge {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
        &[aria-expanded="true"] {
          i {
            &.menu-arrow {
              &:before {
                @include rotate(180);
              }
            }
          }
        }
      }

      &.active {
        > .nav-link {
            background: $sidebar-light-menu-active-bg;
            position: relative;
            i,
            .menu-title,
            .menu-arrow {
              color: $sidebar-light-menu-active-color;
            }
        }
      }
    }

    &:not(.sub-menu) {
      margin-top: .5rem;
      > .nav-item {
        margin-top: 1.2rem;
        > .nav-link {
          margin: 0;
        }
        &:hover {
          > .nav-link {
            background: $sidebar-light-menu-hover-bg;
            color: $sidebar-light-menu-hover-color;                                    
          }
        }
        &.active{
          background: $sidebar-light-menu-active-bg;
          color: $sidebar-light-menu-active-color;
          border-radius: 6px;
        }
      }
    }
    &.sub-menu {
      // margin-bottom: 0;
      // margin-top:0;
      margin: $sidebar-submenu-margin;
      list-style: none;
      padding: $sidebar-submenu-padding;

      .nav-item {
        padding: 0;
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &:hover {
            color: $sidebar-light-submenu-hover-color;
          }
          &.active {
            color: $sidebar-light-submenu-active-color;
            background: transparent;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
//sidebar color variation
.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg;
    .nav {
      .nav-item {
        .nav-link {
          color: $sidebar-dark-menu-color;
          i {
            color: inherit;
            &.menu-icon {
              color: $sidebar-dark-menu-icon-color;
            }
          }
          .menu-title {
            color: inherit;
          }
        }
        &.active {
          > .nav-link {
            background: $sidebar-dark-menu-active-bg;
            .menu-title,
            i {
              color: $sidebar-dark-menu-active-color; 
            }
          }
        }
      }
      &:not(.sub-menu) {
        > .nav-item {
          &:hover {
            > .nav-link {
              background: $sidebar-dark-menu-hover-bg;
              color: $sidebar-dark-menu-hover-color;                                    
            }
          }
        }
      }
      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;
            &:before {
              color: lighten($sidebar-dark-submenu-color, 10%);
            }
            &.active {
              color: $sidebar-dark-menu-active-color;
              background: transparent;
            }
            &:hover {
              color: $sidebar-dark-submenu-hover-color;
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}